import { assocPath, is, lte, path, pathOr, prop } from 'ramda';
import React, { useEffect, useRef } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { changeTypeToElementName, typeIsOnFlexiget } from '../helpers/elements';

const DroppedCard = ({
  id,
  type,
  handleDelete,
  values,
  setState,
  renderToolbar,
  renderVariable,
  isFocus,
  widgetIsEditable,
  elements,
  optionsElement,
  onChangeContentHeight,
  customColors,
  customStyles,
  defaultFont,
  orientation,
  onCreateStyle
}) => {
  const content = path(['contents', id], values);

  const refElement = useRef(null);
  const element = assocPath(['element', 0, 'orientation'], orientation, content);
  const clientHeight = path(['current', 'clientHeight'], refElement);

  const ElementFlexiget = elements[changeTypeToElementName(type)];

  useEffect(() => {
    if (lte(path(['size', 'height'], element), clientHeight)) {
      onChangeContentHeight(clientHeight);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientHeight]);

  const onChangeElement = (v) => setState(assocPath(['values', 'contents', id, 'element'], (is(Array, v) ? v : [v])));

  return (
    <>
      {widgetIsEditable && isFocus && (
        <button
          onClick={() => handleDelete(id)}
          className="btn-remove-element opacity-100 absolute z-10 right-0 -top-[23px] bg-flexibranche-lightblue border-none cursor-pointer px-2"
        >
          <DeleteOutlined className="text-xs text-white" />
        </button>
      )}
      <div className="flexiget relative flex" ref={refElement}>
        {typeIsOnFlexiget(type)(optionsElement) && (
          <ElementFlexiget
            value={prop('element', element)}
            onChange={onChangeElement}
            renderToolbar={renderToolbar}
            renderVariable={renderVariable}
            show={isFocus}
            defaultFont={defaultFont}
            customColors={customColors}
            customStyles={customStyles}
            orientation={orientation}
            elementWidth={pathOr(794, ['size', 'width'], element)}
            onCreateStyle={onCreateStyle}
          />
        )}
      </div>
    </>
  );
};

export default DroppedCard;
