import { assoc, compose, equals, gt, length, prop, not, isNil, defaultTo, mergeRight } from 'ramda';
import React, { useEffect, useState } from 'react';

const position = 'bottomCenter';
const defaultPageSize = 5;
const current = 1;
const totalBoundaryShowSizeChanger = 5;

const getPageSizePreference = (pageKey) => {
  const value = sessionStorage.getItem('pageSizePreference');
  const parsedValue = JSON.parse(value);

  if (isNil(pageKey)) { return parsedValue; }

  return prop(pageKey, parsedValue);
};

const setPageSizePreference = (pageKey, value = defaultPageSize) => {
  let pageSizePreference = getPageSizePreference();
  const withDefaults = mergeRight(pageSizePreference);
  pageSizePreference = withDefaults({ [pageKey]: value });

  sessionStorage.setItem('pageSizePreference', JSON.stringify(pageSizePreference));
};

const usePagination = ({ itemsNumber, type, pageSizeKey }) => {
  let preferredPageSize = isNil(pageSizeKey) ? null : getPageSizePreference(pageSizeKey);
  preferredPageSize = defaultTo(defaultPageSize)(preferredPageSize);

  const showTotal = (total) => equals(type, 'frame') && <span className="mr-2">Total de {total} trame{gt(total, 1) ? 's' : ''}</span>;
  const defaultPageSizeOptions = [5, 10, 15, 20, 25, 50];
  const [pagination, setPagination] = useState({
    position, current, pageSize: preferredPageSize, totalBoundaryShowSizeChanger, defaultPageSize, pageSizeOptions: defaultPageSizeOptions, total: itemsNumber, showTotal
  });

  const onChangePagination = (pagination, _filters, _sorter, extra) => {
    const currentDataSource = prop('currentDataSource', extra);
    const totalItems = length(currentDataSource);
    const pageSizeOptions = defaultPageSizeOptions.filter(option => option < totalItems);
    const pageSize = prop('pageSize', pagination);

    setPagination(
      compose(
        assoc('pageSizeOptions', pageSizeOptions),
        assoc('total', totalItems),
        assoc('pageSize', pageSize),
        assoc('current', prop('current', pagination))
      )
    );

    if (not(isNil(pageSizeKey))) { setPageSizePreference(pageSizeKey, pageSize); }
  };

  // To update the total number of elements used for pagination
  useEffect(() => {
    setPagination({ ...pagination, total: itemsNumber });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsNumber]);

  return { pagination, onChangePagination };
};

export default usePagination;
