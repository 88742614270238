import React, { useState } from 'react';
import { prop } from 'ramda';
import { PlusOutlined } from '@ant-design/icons';
import { Title, Actions, LinkButton } from '../../components';
import withUser from '../../withUser';
import QGetAllFrames from '../../_graphql/queries/documents/QGetAllFrames';
import useCompare from '../../hooks/useCompare';
import FramesList from './FramesList';

const MyFrames = ({ userIsAdmin, userTeam }) => {
  const [refetch, setRefetch] = useState(false);
  const {
    loadingOnCompareAll,
    loadingOnCompareOnRow,
    loadingOnCompareOnSelected,
    onCompare
  } = useCompare(setRefetch);

  return (
    <QGetAllFrames
      args={{
        teamId: prop('teamId', userTeam), refetch, setRefetch
      }}
    >
      {({ allFrames }) => (
        <>
          <Actions
            alignButtonEndRight
            title={(
              <Title
                title="mes trames"
                icon="/images/icon-comparer@3x.png"
                iconSize={[20, 22]}
                iconOffset={-2}
                filter="invert(100%)"
              />
            )}
            buttons={(
              <LinkButton
                to="/trames/creer"
                size="large"
                icon={<PlusOutlined />}
                disabled={loadingOnCompareOnRow || loadingOnCompareOnSelected}
              >
                Créer une trame
              </LinkButton>
            )}
          />

          <FramesList
            userIsAdmin={userIsAdmin}
            onCompare={onCompare}
            loadingOnCompare={loadingOnCompareAll || loadingOnCompareOnSelected}
            loadingOnCompareOnRow={loadingOnCompareOnRow}
            frames={allFrames}
            setRefetch={setRefetch}
          />
        </>
      )}
    </QGetAllFrames>
  );
};

export default withUser(MyFrames);
