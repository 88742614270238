import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { concat, equals, length } from 'ramda';
import { isNotNilOrEmpty } from 'ramda-adjunct';
import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import Loading from '../../../../components/Loading';
import { TreeProvider, useTreeContext } from '../../../../components/treeStructure/treeContext';
import Trees from '../../../../components/treeStructure/Trees';
import useFetchQuery from '../../../../hooks/useFetchQuery';
import withUser from '../../../../withUser';
import { SCOPE_OFFER } from '../../../../_CONST';
import { QAllDocumentsQuery } from '../../../../_graphql/queries/documents/QAllDocuments';
import { Collapse } from '../../../../components';
import DocumentTreeRow from './DocumentTreeRow';
import UploadRawDocumentButton from './UploadRawDocumentButton';

const DocumentsList = (props) => {
  const {
    args,
    offerStatus,
    userIsAdmin,
    documentsByRootOfferId,
    allDocuments,
    userCanCreateOfferDocument,
    reFetch,
    setDocuments,
    isOfferPath,
    setRefetchWidgets,
    folders
  } = props;
  const [collapseIsOpen, setCollapseIsOpen] = useState(true);
  const { scope, offerId } = args;
  const { addTree, expandedKeys } = useTreeContext();

  const history = useHistory();

  const userCanEditDocuments = (equals(scope, SCOPE_OFFER) && equals(offerStatus, 'draft')) || (equals(scope, 'global') && userIsAdmin);

  const handleCreateNewFolder = () => addTree(null, expandedKeys)();

  return (
    <Collapse
      collapseIsOpen={collapseIsOpen}
      defaultActiveKey={['1']}
      setCollapseIsOpen={setCollapseIsOpen}
      className="section-doc"
      title={equals(scope, 'global') ? 'Documents de l\'équipe' : 'Documents de l\'offre'}
      actions={
        userCanCreateOfferDocument && (
          <>
            {collapseIsOpen && (
              <Button
                onClick={handleCreateNewFolder}
                icon={<PlusOutlined />}
              >
                Créer un dossier racine
              </Button>
            )}
            {equals(scope, SCOPE_OFFER) && (
              <UploadRawDocumentButton
                scope="offer"
                offerId={offerId}
                reFetch={reFetch}
              />
            )}
            <Button
              onClick={() => history.push(`/offre/${offerId}/edition/documents/creer`) }
              icon={<PlusOutlined />}
            >
              Créer un document
            </Button>
          </>
        )
      }
    >
      <Trees
        elements={concat(folders, allDocuments)}
        setElements={setDocuments}
        setRefetchWidgets={setRefetchWidgets}
        userIsAdmin={userIsAdmin}
        ElementRow={(
          <DocumentTreeRow
            isOfferPath={isOfferPath}
            {...props}
            originalDocuments={allDocuments}
            userCanEditDocuments={userCanEditDocuments}
            documentsByRootOfferId={documentsByRootOfferId}
            refetch={reFetch}
            setRefetchWidgets={setRefetchWidgets}
          />
        )}
      />
    </Collapse>
  );
};

const DocumentListWrapper = ({ cacheKey, args, setIsLoadingOfferItem, isLoadingOfferItem, ...restProps }) => {
  const { allDocuments, isLoading, error, reFetch } = useFetchQuery({
    query: QAllDocumentsQuery,
    dataProp: 'allDocuments',
    args,
    defaultData: [],
    cacheKey
  });
  const forceRefresh = useRef(false);

  const [documents, setDocuments] = useState([]);
  useEffect(() => {
    setIsLoadingOfferItem && setIsLoadingOfferItem(isLoading);
    if (!isLoading && (isNotNilOrEmpty(allDocuments) || forceRefresh.current)) {
      forceRefresh.current = false;
      setDocuments(allDocuments);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    if (!isLoading && length(documents) !== length(allDocuments)) {
      forceRefresh.current = true;
      reFetch();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, documents, allDocuments]);

  if (isLoading || isLoadingOfferItem) return <Loading/>;
  if (error) return <p>Erreur</p>;

  const props = {
    args,
    reFetch,
    ...restProps
  };

  return (
    <TreeProvider {...props} elements={documents}>
      <DocumentsList
        allDocuments={documents}
        setDocuments={setDocuments}
        args={args}
        reFetch={reFetch}
        setIsLoadingOfferItem={setIsLoadingOfferItem}
        {...restProps}
      />
    </TreeProvider>
  );
};

export default withUser(DocumentListWrapper);
