/* eslint-disable react-hooks/exhaustive-deps */
import { message } from 'antd';
import { compose, cond, equals, filter, includes, length, map, prop, propEq } from 'ramda';
import { useEffect, useState } from 'react';
import { useRelayEnvironment } from 'react-relay';
import { isNotEmpty } from 'ramda-adjunct';
import { errorMessage } from '../utils/messageMutation';
import GenerateComparePdfMutation from '../_graphql/mutations/documents/GenerateComparePdfMutation';
import FinishedComparisonsSubscription from '../_graphql/subscriptions/comparePDF/FinishedComparisonsSubscription';

const useCompare = (setRefetch) => {
  const [loadingOnCompareAll, setLoadingOnCompareAll] = useState(false);
  const [loadingOnCompareOnRow, setLoadingOnCompareOnRow] = useState(false);
  const [loadingOnCompareOnSelected, setLoadingOnCompareOnSelected] = useState(false);
  const environment = useRelayEnvironment();

  useEffect(() => {
    const finishedComparisons = FinishedComparisonsSubscription(environment);
    return () => finishedComparisons.dispose();
  }, []);

  const updateLoading = (type, bool) => cond([
    [equals('all'), () => setLoadingOnCompareAll(bool)],
    [equals('row'), () => setLoadingOnCompareOnRow(bool)],
    [equals('selected'), () => setLoadingOnCompareOnSelected(bool)]
  ])(type);

  const onCompare = (type, ids) => {
    updateLoading(type, true);
    const nbIds = length(ids);
    const areManyComparisons = nbIds > 1;

    message.info(`${ids.length} comparaison${ids.length > 1 ? 's' : ''} en cours, veuillez patienter...`);

    GenerateComparePdfMutation({ ids }, (ok, error, allComparisons) => {
      updateLoading(type, false);
      if (ok && !error) {
        const hasFailedComparison = compose(includes('failed'), map(prop('status')))(allComparisons);
        const errorComparisons = filter(propEq('status', 'error'))(allComparisons);
        const hasErrorComparison = isNotEmpty(errorComparisons);
        if (hasErrorComparison) {
          message.warning(`Impossible de générer ${length(errorComparisons) > 1 ? 'plusieurs' : 'une'} comparaison${length(errorComparisons) > 1 ? 's' : ''}, veuillez contacter l'équipe Flexidocs`);
        }
        if (hasFailedComparison) {
          const start = areManyComparisons ? `Les ${hasErrorComparison ? 'autres' : ''} comparaisons ont rendues` : 'La comparaison a rendue';
          const msgWarning = `${start} des différences. Vous pouvez les consulter en allant sur les informations de générations.`;
          message.warning(msgWarning);
        } else {
          const msgSuccess = areManyComparisons ? `Les ${hasErrorComparison ? 'autres' : ''} comparaisons n'ont pas rendues de différence.` : 'La comparaison n\'a pas rendue de différence.';
          message.success(msgSuccess);
        }
        if (setRefetch) setRefetch(true);
      } else {
        if (equals('[DOCUMENT RESOLVER][Generate compare pdf] ❌ All comparisons failed during the process', error)) {
          message.error(`Impossible de générer ${length(ids) > 1 ? 'ces' : 'cette'} comparaison${length(ids) > 1 ? 's' : ''}, veuillez contacter l'équipe Flexidocs`);
        } else {
          errorMessage();
        }
      }
    });
  };

  return {
    loadingOnCompareAll,
    loadingOnCompareOnRow,
    loadingOnCompareOnSelected,
    onCompare
  };
};

export default useCompare;
