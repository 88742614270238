import React from 'react';
import { addIndex, map, propEq, replace, __, reject, propSatisfies, isNil, either, isEmpty, prop, compose, head, propOr, groupBy, path, reverse, keys } from 'ramda';
import { CaretRightFilled } from '@ant-design/icons';
import { Collapse, Timeline, Popconfirm } from 'antd';
import { Link } from 'react-router-dom';
import { Dot } from '..';
import UpdateVersionStatusMutation from '../../_graphql/mutations/versions/UpdateVersionStatusMutation';
import RemoveVersionMutation from '../../_graphql/mutations/versions/RemoveVersionMutation';
import { getStatusLabel } from '../../lib/status';
import { errorMessage, successMessage } from '../../utils/messageMutation';
import RemoveCacheMutation from '../../_graphql/mutations/RemoveCacheMutation';
import VersionsTreeItem from './VersionsTreeItem';

const mapIndexed = addIndex(map);

const timelineItemStyle = { paddingBottom: '10px' };

const panelItemStyle = { borderBottom: '1px solid #EBEAEA' };

const VersionsTree = ({
  items,
  userCanEdit,
  editLinkPattern,
  suppressionLabel,
  archiveLabel,
  nameField = 'name',
  setRefetch
}) => {
  const onArchive = (versionId, itemId) => () => {
    UpdateVersionStatusMutation({
      versionId, status: 'archived', itemId
    }, (ok, error) => {
      if (ok && !error) {
        successMessage('version', 'archivée', false, true);
      } else {
        errorMessage();
      }
    });
  };

  const onRemove = (versionId, itemId) => () => {
    RemoveVersionMutation({ versionId }, (ok, error) => {
      if (ok && !error) {
        successMessage('version', 'supprimée', false, true);
        RemoveCacheMutation({ key: `documents-${itemId}` }, () => {});
        RemoveCacheMutation({ key: `widgets-${itemId}` }, () => {});
        !!setRefetch && setRefetch(true);
      } else {
        errorMessage();
      }
    });
  };

  const statusIs = (status, version) => propEq('status', status, version);
  const buildEditLink = replace(':itemId', __, editLinkPattern);

  items = reject(propSatisfies(isNil, 'version'))(items);

  return (
    <Collapse
      expandIcon={({ isActive }) => (
        <CaretRightFilled
          className={`!text-[18px] relative !mr-1.5 top-0.5 ${isActive ? '!text-flexibranche-lightblue' : '!text-gray-600'}`}
          rotate={isActive ? 90 : 0}
        />
      )}
      bordered={false}
      className="versions_tree bg-white"
    >
      {map(item => {
        if (either(isNil, isEmpty)(prop('versions', item))) {
          return null;
        }

        const lastVersion = compose(
          head,
          propOr([], 'versions')
        )(item);

        const majorVersions = compose(
          groupBy(path(['version', 'major'])),
          propOr([], 'versions')
        )(item);

        const majorVersionsNumbers = compose(
          reverse,
          keys
        )(majorVersions);

        return (
          <Collapse.Panel
            key={prop('id', item)}
            // eslint-disable-next-line no-restricted-syntax
            style={panelItemStyle}
            header={(
              <VersionsTreeItem
                name={prop(nameField, lastVersion)}
                dates={prop('dates', lastVersion)}
                version={prop('version', lastVersion)}
                editLink={buildEditLink(prop('id', lastVersion))}
                suppressionLabel={suppressionLabel}
                archiveLabel={archiveLabel}
              />
            )}
          >
            <Timeline mode="left">
              {map(versionNumber => {
                const minorVersions = propOr([], versionNumber, majorVersions);

                return (
                  <Timeline.Item
                    key={`${item.id}-version-${versionNumber}`}
                    color="green"
                    dot={<Dot color="#61c397" />}
                    // eslint-disable-next-line no-restricted-syntax
                    style={timelineItemStyle}
                  >
                    Version {versionNumber}

                    <Timeline mode="left" className="!mt-3">
                      {mapIndexed((itemVersion, index) => {
                        const itemId = path(['version', 'itemId'], itemVersion);
                        const versionId = path(['version', 'versionId'], itemVersion);
                        const major = path(['version', 'major'], itemVersion);
                        const minor = path(['version', 'minor'], itemVersion);

                        return (
                          <Timeline.Item
                            key={`${prop('id', item)}-version-${versionNumber}-${itemVersion.id}`}
                            color={index !== 0 ? 'gray' : 'blue'}
                            dot={<Dot color={index !== 0 ? 'gray' : '#0197dc'} />}
                            // eslint-disable-next-line no-restricted-syntax
                            style={timelineItemStyle}
                          >
                            <Link to={buildEditLink(prop('id', itemVersion))}>Version {`${major}.${minor}`}</Link>
                            <span>
                      &nbsp;({getStatusLabel(path(['version', 'status'], itemVersion))})
                            </span>

                            {userCanEdit && (
                              <div className="float-right">
                                {statusIs('archived', prop('version', itemVersion))
                                  ? (
                                    <Popconfirm
                                      placement="topRight"
                                      title={suppressionLabel}
                                      onConfirm={onRemove(versionId, itemId)}
                                      okText="Oui"
                                      cancelText="Non"
                                    >
                                      <a href="#">Supprimer</a>
                                    </Popconfirm>
                                  )
                                  : (
                                    <Popconfirm
                                      placement="topRight"
                                      title={archiveLabel}
                                      onConfirm={onArchive(versionId, itemId)}
                                      okText="Oui"
                                      cancelText="Non"
                                    >
                                      <a href="#">Archiver</a>
                                    </Popconfirm>
                                  )
                                }
                              </div>
                            )}
                          </Timeline.Item>
                        );
                      })(minorVersions)}
                    </Timeline>
                  </Timeline.Item>
                );
              })(majorVersionsNumbers)}
            </Timeline>
          </Collapse.Panel>
        );
      })(items)}
    </Collapse>
  );
};

export default VersionsTree;
