import React from 'react';
import { isNil } from 'ramda';

const Section = ({
  img,
  title,
  children,
  actions,
  style = {},
  titleStyle = {},
  className,
  optionTitle
}) => (
  <div className={`bg-white px-8 py-4 my-6 relative ${className}`}>
    <div
      className={`flex mb-4 ${actions ? 'justify-between items-center' : ''}`}
      // eslint-disable-next-line no-restricted-syntax
      style={style}
    >
      {!isNil(img) && (
        <img
          src={img}
          width="97px"
          alt={img}
        />
      )}

      <div className="inline-flex space-x-2 items-center w-full">
        <h2
          className="mb-0 text-flexibranche-blue font-bold text-lg uppercase"
          // eslint-disable-next-line no-restricted-syntax
          style={titleStyle}
        >
          {title}
        </h2>
        {optionTitle}
      </div>
      <div className="flex gap-4">
        {actions}
      </div>
    </div>
    {children}
  </div>
);

export default Section;
