import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import { Activity } from '../team/components';
import Menu from '../../containers/menu';
import Layout from '../../layout/default';
import withUser from '../../withUser';

const gridStyle = {
  padding: '15px 40px 15px 30px',
  justifyContent: 'left'
};

const MenuItem = styled(Link)`
  font-size: 19px;
  color: #005982;
  text-transform: uppercase;
  font-weight: bold;
`;

const Home = ({ userIsAdmin }) => {
  return (
    <Layout menu={<Menu />}>
      <div>
        <Row gutter={40}>
          {userIsAdmin && (
            <Col span="12">
              <MenuItem to="/offre/creer">
                {/* eslint-disable-next-line no-restricted-syntax */}
                <div style={gridStyle} className="bg-white flex items-center gap-8">
                  <img src="/images/illu-1@2x.png" alt="créer une offre" width="86" />
                  Créer une offre
                  <img src="/images/chevron-left.svg" alt="créer une offre" className="ml-auto" />
                </div>
              </MenuItem>
            </Col>
          )}
          <Col span="12">
            <MenuItem to="/equipe">
              {/* eslint-disable-next-line no-restricted-syntax */}
              <div style={gridStyle} className="bg-white flex items-center gap-8">
                <img src="/images/illu-2-bis@2x.png" alt="gérer mon équipe" width="86" />
                Gérer mon équipe
                <img src="/images/chevron-left.svg" alt="gérer mon équipe" className="ml-auto" />
              </div>
            </MenuItem>
          </Col>
        </Row>
        <Activity />
      </div>
    </Layout>
  );
};

export default withUser(Home);
