import React from 'react';
import { List } from 'antd';
import { Link } from 'react-router-dom';
import { isNil, prop, path, pathOr } from 'ramda';
import { QTeamActions } from '../../../_graphql/queries';
import { Section } from '../../../components';
import TeamInvite from './TeamInvite';

const Activity = () => (
  <>
    <Section title="Activité de mon équipe">
      <QTeamActions page={1}>
        {({ teamActions }) => {
          if (isNil(teamActions)) {
            return (
              <p>{'Vous n\'avez pas d\'équipe active, vous pouvez en créer une'} <Link to="/equipe/créer">ici</Link></p>
            );
          }

          const { docs, pagination } = teamActions;
          return (
            <List
              dataSource={docs}
              pagination={pagination}
              renderItem={item => {
                return (
                  <List.Item>
                    {prop('when', item)}
                    {path(['user', 'email'], item)}
                    {pathOr('not defined', ['data', 'offer', 'offerNumber'], item)}
                    {prop('actionName', item)}
                  </List.Item>
                );
              }}
            />
          );
        }}
      </QTeamActions>
    </Section>
    <Section title="Mes invitations">
      <TeamInvite />
    </Section>
  </>
);

export default Activity;
